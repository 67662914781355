import React from "react";

interface IProps {
  children: any;
  className?: string;
  onClick?: () => void;
  ariaLabel: string;
  type: any;
  href?: string;
  backgroundColor?: string;
  disabled?: boolean;
}

const PopInButton = (props: IProps) => {
  let className =
    "popOutBtn__thumbnail w-full min-w-[120px] min-h-[40px] font-mono h-full text-white flex flex-col justify-center items-center text-xl " + (!props.backgroundColor ? ' bg-brand-pink ' : props.backgroundColor);
  return (
    <div className={"popOutBtn h-full w-full " + props.className}>
      {!props.href ? (
        <button
            disabled={props.disabled}
          type={props.type}
          aria-label={props.ariaLabel}
          onClick={props.onClick}
          className={className + ' ' + (props.disabled ? ' cursor-not-allowed ' : ' cursor-pointer ')}
        >
          {props.children}
        </button>
      ) : (
        <a
          target={"_blank"}
          rel="noreferrer"
          href={props.href}
          aria-label={props.ariaLabel}
          className={className}
        >
          {props.children}
        </a>
      )}
    </div>
  );
};

export default PopInButton;
