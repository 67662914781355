import React, {useState} from 'react'
import ContentContainer from "./ContentContainer";
import EmailSub from "./EmailSub";
import { trackEvent } from 'fathom-client';

interface IProps {
 text: string;
 tag: string;
 className?: string;
}

const SubsribeSection = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email}),
            });

            if (response.ok) {
                setSuccess(true);
                setError('');
                trackEvent('NEWSLETTER_SUBSCRIBE');
            } else {
                const data = await response.json();
                setError(data.error || 'Something went wrong');
                setSuccess(false);
            }
        } catch (err) {
            setError('Something went wrong');
            setSuccess(false);
        }

        setIsLoading(false);
    };

    return (
        <div className={"flex h-full w-full flex-col items-center bg-white"}>
            <ContentContainer
                className={"mt-24 mb-24 flex flex-col items-center w-full"}
            >
                <div className={"flex w-full flex-col items-center sm:w-2/3"}>
                    <div className={"text-xl text-center font-extrabold  md:text-2xl"}>
                        {props.text}
                    </div>
                    <div className={"w-full self-center pt-8  md:self-start flex flex-col items-center justify-center"}>

                        <EmailSub tag={props.tag}/>
                    </div>
                </div>
            </ContentContainer>
        </div>


    );
}

export default SubsribeSection
