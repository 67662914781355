import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useEffect, useState } from "react";
import { usePageVisibility } from "../lib/usePageVisibility";

import benjamin from "../public/Benjamin.webp";
import contactUsPicNoBg from "../public/getInContact.webp";
import oana from "../public/Oana.webp";
import profilePicNoBg from "../public/us_no_bg.webp";

// @ts-ignore
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";
import AfterTeam from "../components/AfterTeam";
import AnimatedWord from "../components/AnimatedWord";
import BeforeTeam from "../components/BeforeTeam";
import Custom from "../components/Custom";
import { FloatingNavStatus } from "../components/FloatingNav";
import Logo from "../components/Logo";
import SectionHeader from "../components/SectionHeader";
import SubsribeSection from "../components/SubsribeSection";
import { getOpacityFromBottomTransition } from "../lib/Transitions";
import { useTotalVisibilityTimer } from "../lib/useTotalVisibilityTimer";
import { Gradient } from "../lib/gradient";
dynamic(() => import("../components/EmailSub"));
const FooterBar = dynamic(() => import("../components/FooterBar"));
const Header = dynamic(() => import("../components/Header"));
const Member = dynamic(() => import("../components/Member"));
const BookACall = dynamic(() => import("../components/BookACall"));
const Package = dynamic(() => import("../components/Package"));
const ContentContainer = dynamic(
  () => import("../components/ContentContainer")
);
const FloatingNav = dynamic(() => import("../components/FloatingNav"));
const PopInButton = dynamic(() => import("../components/PopInButton"));

const Home: NextPage = () => {
  const Scroll = require("react-scroll");
  let scroller = Scroll.scroller;
  const TrainingsSection = Scroll.Element;

  const PackagesSection = Scroll.Element;
  const AboutSection = Scroll.Element;
  const TopSection = Scroll.Element;
  const ContactSection = Scroll.Element;

  const StartNowSection = Scroll.Element;

  const [floatingNavStatus, setFloatingNavStatus] = useState<FloatingNavStatus>(
    FloatingNavStatus.TOP
  );

  const trainingsInView = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px",
  });

  const workshopsInView = useInView({
    triggerOnce: false,
    rootMargin: "-0px 0px",
  });

  const packagesInView = useInView({
    triggerOnce: false,
    rootMargin: "-0px 0px",
  });

  const aboutInView = useInView({
    triggerOnce: false,
    rootMargin: "-50px 0px",
  });

  const topInView = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px",
  });

  const contactInView = useInView({
    triggerOnce: false,
    rootMargin: "-300px 0px",
  });

  useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const contactImageSectionInView = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  });

  const contactActionSectionInView = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  });

  const isPageVisible = usePageVisibility();

  // Change the title based on page visibility

  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient("#gradient-canvas");
  }, []);

  //enum with three values one, two,three
  enum animStatus {
    ONE,
    TWO,
    THREE,
  }

  const [animStatusInstance, setAnimStatusInstance] = useState<animStatus>(
    animStatus.ONE
  );
  const [lastTimerVal, setLastTimerVal] = useState<number>(0);

  const timerVal = useTotalVisibilityTimer(isPageVisible);
  useEffect(() => {
    //iterate between page enum after timerVal
    if (timerVal > lastTimerVal + 1) {
      setLastTimerVal(timerVal);
      if (animStatusInstance === animStatus.ONE) {
        setAnimStatusInstance(animStatus.TWO);
      } else if (animStatusInstance === animStatus.TWO) {
        setAnimStatusInstance(animStatus.THREE);
      } else if (animStatusInstance === animStatus.THREE) {
        setAnimStatusInstance(animStatus.ONE);
      }
    }
  }, [timerVal]);

  useEffect(() => {
    if (packagesInView.inView) {
      setFloatingNavStatus(FloatingNavStatus.PACKAGES);
    } else if (floatingNavStatus === FloatingNavStatus.PACKAGES) {
      setFloatingNavStatus(FloatingNavStatus.TOP);
    }

    if (workshopsInView.inView) {
      setFloatingNavStatus(FloatingNavStatus.WORKSHOPS);
    } else if (floatingNavStatus === FloatingNavStatus.WORKSHOPS) {
      setFloatingNavStatus(FloatingNavStatus.TOP);
    }

    if (topInView.inView) {
      setFloatingNavStatus(FloatingNavStatus.TOP);
    }

    if (trainingsInView.inView) {
      setFloatingNavStatus(FloatingNavStatus.TRAININGS);
    } else if (floatingNavStatus === FloatingNavStatus.TRAININGS) {
      setFloatingNavStatus(FloatingNavStatus.TOP);
    }

    if (aboutInView.inView) {
      setFloatingNavStatus(FloatingNavStatus.ABOUT);
    } else if (floatingNavStatus === FloatingNavStatus.ABOUT) {
      setFloatingNavStatus(FloatingNavStatus.TOP);
    }

    if (contactInView.inView) {
      setFloatingNavStatus(FloatingNavStatus.CONTACT);
    } else if (floatingNavStatus === FloatingNavStatus.CONTACT) {
      setFloatingNavStatus(FloatingNavStatus.TOP);
    }
  }, [
    packagesInView.inView,
    contactInView.inView,
    aboutInView.inView,
    trainingsInView.inView,
    topInView.inView,
    workshopsInView.inView,
    floatingNavStatus,
  ]);

  const startYear = 2015;
  const yearsOfExperience = new Date().getFullYear() - startYear;

  return (
    <>
      <FloatingNav
        scroller={scroller}
        floatingNavStatus={floatingNavStatus}
        initialShowStatus={true}
      />
      <Header scroller={scroller} floatingNavStatus={floatingNavStatus} />
      <NextSeo
        title="Scrumify - We Help Teams Build Value-Driven Software By Providing Hands-On Scrum Trainings"
        description="Powering Up Software Teams."
        openGraph={{
          url: "https://www.scrumify.at",
          title: "Scrumify",
          description: "Powering Up Software Teams.",
          images: [
            {
              url: "https://res.cloudinary.com/bt101/image/upload/v1674077719/opengraph_ai_retro_ieth6r.png",
              width: 1200,
              height: 630,
              alt: "Oana & Benjamin - Scrumify",
              type: "image/png",
            },
          ],
          site_name: "Scrumify",
        }}
        twitter={{
          handle: "@benithors",
          site: "@benithors",
          cardType: "summary_large_image",
        }}
      />

      <div
        className={
          " break-word flex h-full min-h-screen w-full flex-col sm:break-normal   "
        }
      >
        <Head>
          <title>Scrumify</title>

          <link rel="icon" href="/favicon.ico" />
        </Head>

        <TopSection
          name="topScrollToElement"
          className={
            " flex h-[calc(100vh-100px)] max-h-[1200px] min-h-[600px] w-full flex-col items-center justify-center sm:min-h-[700px] md:h-[calc(100vh-150px)] md:min-h-[500px]"
          }
        >
          <div
            ref={topInView.ref}
            className={
              "w-sc relative flex h-full flex-col items-center overflow-hidden font-mono"
            }
          >
            <canvas
              id="gradient-canvas"
              className={"-z-20 h-full w-screen  "}
            />
            <ContentContainer
              className={
                "absolute flex h-full w-full flex-col items-center md:flex-row md:items-end md:justify-center  "
              }
            >
              <div className={"w-screen bg-brand-black   md:hidden"}>
                <Logo className={"py-4 pl-4     "} />
              </div>

              <div
                className={
                  "flex h-full flex-grow flex-row items-end md:mb-12 md:mr-4 lg:mb-8"
                }
              >
                <div
                  className={
                    "relative mt-8 flex   flex-col flex-wrap text-white "
                  }
                >
                  <div
                    className={
                      "flex flex-col font-mono text-6xl font-extrabold sm:text-7xl md:text-6xl lg:text-[5rem]   "
                    }
                  >
                    <AnimatedWord
                      gradient={
                        "bg-gradient-to-l from-brand-turquoise to-purple-600"
                      }
                      animate={animStatusInstance === animStatus.ONE}
                      word={"TRAIN"}
                    />
                    <AnimatedWord
                      gradient={
                        "bg-gradient-to-l from-brand-pink to-brand-turquoise"
                      }
                      animate={animStatusInstance === animStatus.TWO}
                      word={"ITERATE"}
                    />
                    <AnimatedWord
                      gradient={
                        "bg-gradient-to-r from-brand-pink to-purple-600"
                      }
                      animate={animStatusInstance === animStatus.THREE}
                      word={"EVOLVE"}
                    />
                  </div>

                  <div
                    className={
                      "mb-4 mt-8   flex h-14 w-full flex-row justify-start  md:items-baseline "
                    }
                  >
                    <PopInButton
                      type={"button"}
                      ariaLabel={
                        "Scroll to the next section explaining our services"
                      }
                      onClick={() => {
                        scroller.scrollTo("startNowScrollToElement", {
                          duration: 1500,
                          delay: 100,
                          smooth: true,
                        });
                      }}
                    >
                      SHOW ME HOW
                    </PopInButton>
                  </div>
                </div>
              </div>

              <div
                className={
                  "flex w-full flex-row  items-end sm:w-3/4 md:w-[27rem] lg:w-2/4"
                }
              >
                <div className={"-z-10  w-full translate-y-2"}>
                  <Image
                    src={profilePicNoBg}
                    width={1370}
                    height={1080}
                    priority={true}
                    alt={"Oana Moraru and Benjamin Thorstensen"}
                  />
                </div>
              </div>
            </ContentContainer>
          </div>
        </TopSection>

        <StartNowSection name="startNowScrollToElement">
          <div className={"relative flex flex-col items-center   bg-white "}>
            <svg
              className={"absolute left-0 top-3/4 translate-y-[0.04rem] "}
              width="48"
              height="115"
              viewBox="0 0 48 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.1586 41.9961C10.6258 34 10.2731 34.9979 0 27V95.4823V112.978C38.3668 126.474 70.5 66.9436 25.1586 41.9961Z"
                fill="url(#paint0_linear_0_1)"
              />
              <path d="M0 0V53.5C33 51.5 33 4 0 0Z" fill="#FF00B5" />
              <defs>
                <linearGradient
                  id="paint0_linear_0_1"
                  x1="47"
                  y1="80.5"
                  x2="-12.0726"
                  y2="85.0493"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00FFD9" />
                  <stop offset="1" stopColor="#00FFD9" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <ContentContainer
              className={
                "flex flex-col items-center justify-between   py-12  md:pb-14 md:pt-24"
              }
            >
              <div className={" z-10 flex  flex-col  text-xl font-bold "}>
                <h1
                  className={
                    "text-center tracking-[0.2em] text-brand-text-turquoise"
                  }
                >
                  Our Mission
                </h1>

                <div
                  className={
                    "max-w-[600px]  text-center font-mono text-3xl font-bold   tracking-wide md:text-5xl"
                  }
                >
                  Accelerating success with hands-on trainings.
                </div>
              </div>

              <div
                className={
                  "flex w-full flex-col items-center justify-center space-y-8 pt-8  md:flex-row md:space-y-0 "
                }
              >
                <BeforeTeam />

                <div className={"flex  w-48 flex-col items-center  "}>
                  <div className={"w-20"}>
                    <svg
                      className={"rotate-90 md:rotate-0"}
                      width="100%"
                      height="100%"
                      viewBox="0 0 486 497"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M210.5 25L459 270"
                        stroke="#FF00B5"
                        strokeWidth="70"
                      />
                      <path
                        d="M215 472.5L460.5 222"
                        stroke="#FF00B5"
                        strokeWidth="70"
                      />
                      <path d="M0 247H417" stroke="#FF00B5" strokeWidth="70" />
                    </svg>
                  </div>
                </div>

                <AfterTeam></AfterTeam>
              </div>
            </ContentContainer>
          </div>
        </StartNowSection>

        <div className={"flex w-full flex-col items-center text-white "}>
          <ContentContainer className={"flex w-screen flex-col items-center "}>
            <div
              className={
                "my-14 flex flex-col items-center justify-center text-xl font-bold "
              }
            >
              <div
                className={
                  "flex flex-col items-center justify-center md:flex-row"
                }
              >
                <div className={"w-full text-center md:w-1/2 "}>
                  <h1 className={"tracking-[0.2em] text-brand-pink"}>
                    Hands-on Trainings
                  </h1>
                  <div
                    className={
                      "pt-4 font-mono text-3xl  font-extrabold md:text-5xl lg:text-7xl"
                    }
                  >
                    Why us?
                  </div>
                </div>
                <div className={"w-full md:w-1/2"}>
                  <ul
                    className={
                      " list-disc py-8 pl-12 pt-8  font-sans text-base font-normal tracking-normal "
                    }
                  >
                    <li>ALL SCRUM accountabilities perspectives covered</li>
                    <li>
                      8 years of Agile Product Development experience per
                      Trainer
                    </li>
                    <li>Hands-on Training & Workshop moderation</li>
                    <li>Real-life project experiences, stories & examples</li>
                    <li>
                      Answers to your questions from two different perspectives
                    </li>
                    <li>
                      Male-Female Trainer combination provides the opportunity
                      for bond & trust building
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
        <PackagesSection
          name="packages"
          className={"flex w-full flex-col items-center  bg-cyan-900"}
        >
          <ContentContainer
            className={"flex w-screen flex-col items-center py-20 lg:py-24 "}
          >
            <SectionHeader
              subtitleClassName={"text-center pt-8 pb-8 "}
              title={"PACKAGES"}
              subtitle={"Choose a Package that's right for your Team"}
            />
            <div
              ref={packagesInView.ref}
              className={
                "lg:grid-auto-flow grid  w-full gap-x-[3px]  gap-y-[1px] lg:auto-cols-fr lg:auto-cols-max lg:grid-flow-col lg:grid-cols-3 lg:grid-rows-2"
              }
            >
              <Package
                duration={"duration-1000"}
                title={"Essential"}
                packageDetails={["1 Day", "6-12 Persons"]}
                subtitle={"SCRUM Overview Kickstarter"}
                description={
                  "Get started with SCRUM and learn by hands-on Trainings"
                }
                features={["Hands-on SCRUM Introduction Training"]}
              ></Package>

              <Package
                duration={"duration-1000  lg:delay-150"}
                title={"Starter"}
                packageDetails={["4 Days", "6-12 Persons"]}
                subtitle={"SCRUM Accountabilities Deep Dive"}
                description={"Level Up your SCRUM knowledge"}
                features={[
                  "Hands-on SCRUM Introduction Training",
                  "Hands-on SCRUM Product Owner Training",
                  "Hands-On SCRUM Developer Training",
                  "Hands-on SCRUM Master Training",
                ]}
              ></Package>

              <Package
                duration={"duration-1000 lg:delay-300"}
                title={"Team"}
                packageDetails={["7 Days", "6-12 Persons"]}
                subtitle={"SCRUM Team Ready to Go"}
                description={"The full Package for upcoming SCRUM Superheroes"}
                features={[
                  "Hands-on SCRUM Introduction Training",
                  "Hands-on SCRUM Product Owner Training",
                  "Hands-On SCRUM Developer Training",
                  "Hands-on SCRUM Master Training",
                  "Agile Evolution Starter Workshop",
                  "Product Backlog Setup Workshop",
                  "Prioritization Workshop",
                  "Process & Workflow Workshop",
                ]}
              >
                <div
                  className={"w-full flex-row items-center justify-center"}
                ></div>
              </Package>
            </div>
            <Custom title={"Custom"} className={"mt-8"}>
              <p className={"pt-4 md:text-center"}>
                Customized, Hands-on Trainings for Your Team.
                <br />
                Get in contact with us for a solution that fits your needs.
              </p>
              <div className={"h-20 w-full pb-2  pt-4  lg:w-2/3 "}>
                <PopInButton
                  href={"mailto:hello@scrumify.at"}
                  type={"mail"}
                  ariaLabel={"Write an email to us"}
                >
                  Let&apos;s talk!
                </PopInButton>
              </div>
              <div className={"mt-2"}>
                <BookACall className={"text-sm lg:text-center"} />
              </div>
            </Custom>
            <PopInButton
              type={"button"}
              ariaLabel={"Scroll to Trainings section"}
              className={"mt-8 h-11"}
              backgroundColor={" bg-brand-black "}
              onClick={() => {
                scroller.scrollTo("trainings", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                });
              }}
            >
              See all Trainings!
            </PopInButton>
          </ContentContainer>
        </PackagesSection>

        <SubsribeSection
          text={
            "Signup to our Newsletter and get exclusive Team Building tips that we only share with e-mail\n" +
            "                            Subscribers!"
          }
          tag={"Teambuilding"}
        />

        <AboutSection
          name="about"
          className={"flex w-full flex-col items-center "}
        >
          <div ref={aboutInView.ref}>
            <ContentContainer
              className={"flex w-screen flex-col items-center py-20 lg:py-24"}
            >
              <SectionHeader
                subtitleClassName={"text-center pt-8 pb-8 "}
                title={"ABOUT"}
                subtitle={
                  "Meet the two passionate Trainers for your upcoming Training"
                }
              />
              <Member
                className="md:flex-row-reverse"
                email="oana.moraru@scrumify.at"
                linkedin="https://www.linkedin.com/in/oana-moraru/"
                music="https://ardenite.net/"
                slogan="My heart beats Agile"
                image={oana}
                title="Oana Moraru"
                imageTranslationDirection="-translate-x-96"
                textTranslationDireciton="translate-x-96"
              >
                Hi, I am Oana! - SCRUM Trainer, Agile Coach and Frontwoman of
                the Cyber Pop Rock Band{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ardenite.net/"
                  className="underline underline-offset-2"
                >
                  ARDENITE
                </a>
                !
                <br />
                Becoming the best version of myself and inspiring others is
                something I&apos;m constantly striving to achieve. And I will help
                you and your teams to do the same!
                <br />
                <br />
                In the past {yearsOfExperience} years, I have gathered experience in all Scrum
                accountabilities, including Developer, Product Owner and Scrum
                Master. During that time, I helped multiple teams kick off or
                boost their way of working by using SCRUM.
                <br />
                Not once did I experience a team that did not benefit from this
                approach, nor did any of them want to go back to their previous
                way of working after experiencing it once in my presence.
                <br />
                <br />
              </Member>

              <Member
                className="pt-16 md:flex-row"
                email="benjamin.thorstensen@scrumify.at"
                linkedin="https://www.linkedin.com/in/benithors/"
                twitter="https://twitter.com/benithors"
                slogan="This is where the fun begins"
                image={benjamin}
                title="Benjamin Thorstensen"
                imageTranslationDirection="translate-x-96"
                textTranslationDireciton="-translate-x-96"
              >
                Hi, I am Benjamin, after attending many boring trainings I
                decided to go on a mission: I would bring back the fun and
                excitement in trainings! I believe positive emotions, such as
                enjoyment, are key to long-lasting learning and training
                experiences.
                <br />
                <br />I spent the past {yearsOfExperience} years building software, leading teams
                to become self-organized, training software engineers to become
                the best version of themselves and shipping multiple products
                from draft to go live. At the peak, it was time for me to leave
                and start my mission to be a SCRUM Trainer, Agile Coach with a
                computer science background.
              </Member>
            </ContentContainer>
          </div>
        </AboutSection>

        <TrainingsSection
          name="trainings"
          className={"flex w-full flex-col items-center bg-cyan-900 "}
        >
          <div ref={trainingsInView.ref}>
            <ContentContainer
              className={
                "flex w-screen flex-col items-center pb-20 pt-20 lg:pt-24"
              }
            >
              <SectionHeader
                subtitleClassName={"text-center pt-8 pb-8 "}
                title={"TRAININGS"}
                subtitle={"Choose a Trainings that's right for your Team"}
              />
              <div
                className={
                  "lg:grid-auto-flow grid  w-full gap-x-[3px]  gap-y-[1px] lg:auto-cols-fr lg:auto-cols-max lg:grid-flow-col lg:grid-cols-2 lg:grid-rows-2"
                }
              >
                <Package
                  duration={"duration-1000"}
                  title={"Hands-On SCRUM Introduction"}
                  packageDetails={["1 Day", "6-12 Persons"]}
                  lastPoint={
                    "Recommended for anybody & everybody who wants to learn about SCRUM & Agile"
                  }
                  description={
                    "Get started with SCRUM and learn by hands-on Trainings"
                  }
                  features={[
                    "Overview & basics of SCRUM & Agile",
                    "Hands-on exercises",
                    "Various RL examples from experience",
                    "Self-reflection superpowers",
                  ]}
                ></Package>

                <Package
                  duration={"duration-1000 lg:delay-150"}
                  title={"SCRUM Customer Collaboration"}
                  packageDetails={["1 Day", "6-12 Persons"]}
                  lastPoint={
                    "Recommended for Customers Single Points of Contact & Stakeholders working with a SCRUM team"
                  }
                  description={"Level Up your SCRUM knowledge"}
                  features={[
                    "SCRUM Overview & Deep-dive from the Customer&apos;s\n" +
                      "Perspective",
                    "RL examples & hands-on exercises",
                    "SCRUM Customer benefits, responsibility Kickstarter\n",
                  ]}
                ></Package>
              </div>

              <div
                className={
                  "lg:grid-auto-flow grid  w-full gap-x-[3px]  gap-y-[1px] lg:mt-8 lg:auto-cols-fr lg:auto-cols-max lg:grid-flow-col lg:grid-cols-3 lg:grid-rows-2"
                }
              >
                <Package
                  duration={"duration-1000 lg:delay-150"}
                  title={"Hands-On SCRUM Product Owner"}
                  packageDetails={["1 Day", "6-12 Persons"]}
                  lastPoint={
                    "Recommended for (upcoming) Product Owners, as well as anybody working with them, such as Developers & Scrum Masters, Leadership, etc."
                  }
                  description={"Level Up your SCRUM knowledge"}
                  features={[
                    "RL examples & hands-on exercises",
                    "Product Owner Toolbox Kickstarter",
                  ]}
                ></Package>
                <Package
                  duration={"duration-1000 lg:delay-150"}
                  title={"Hands-On SCRUM Developer"}
                  packageDetails={["1 Day", "6-12 Persons"]}
                  lastPoint={
                    "Recommended for Junior & Senior Developers\n" +
                    "working in a SCRUM team, as well as anybody\n" +
                    "working with them"
                  }
                  description={"Level Up your SCRUM knowledge"}
                  features={[
                    "Dive deeper into the SCRUM Developer",
                    "RL examples & hands-on exercises",
                    "Developer ownership and self-management Kickstarter",
                  ]}
                ></Package>
                <Package
                  duration={"duration-1000 lg:delay-150"}
                  title={"Hands-On SCRUM Master"}
                  packageDetails={["1 Day", "6-12 Persons"]}
                  lastPoint={
                    "Recommended for (upcoming) SCRUM Masters working in a SCRUM team, as well as anybody working with them"
                  }
                  description={"Level Up your SCRUM knowledge"}
                  features={[
                    "Dive deeper into the SCRUM Master",
                    "RL examples & hands-on exercises",
                    "SCRUM Master responsibility Kickstarter",
                  ]}
                ></Package>
              </div>
            </ContentContainer>
          </div>
        </TrainingsSection>

        <SubsribeSection
          tag={"AI"}
          text={
            "Signup to our Newsletter and get exclusive Team Building tips that we only share with e-mail\n" +
            "                            Subscribers!"
          }
        />
        <ContactSection
          name={"contact"}
          className={
            "flex w-full flex-col    items-center overflow-hidden  bg-cyan-900"
          }
        >
          <ContentContainer className={"flex w-screen flex-col items-center "}>
            <div
              ref={contactImageSectionInView.ref}
              className={
                "mt-4 flex h-[calc(100vh/2)] max-h-[600px] min-h-[400px] w-full flex-col  items-center     md:flex-row md:justify-around " +
                getOpacityFromBottomTransition(contactImageSectionInView.inView)
              }
            >
              <div
                className={
                  "md:order-0 order-1 flex  h-full w-full flex-row justify-around"
                }
              >
                <div className={"h-fit max-h-[400px]    w-4/5 self-end   "}>
                  <Image
                    src={contactUsPicNoBg}
                    width={1200}
                    height={1200}
                    sizes={"30vw"}
                    alt={"Oana Moraru and Benjamin Thorstensen"}
                  />
                </div>
              </div>
              <div
                className={
                  "flex h-full w-full flex-row    items-center justify-center "
                }
              >
                <div
                  className={
                    "flex  w-full flex-col items-center md:block md:w-3/5"
                  }
                >
                  <SectionHeader
                    title={"CONTACT"}
                    subtitle={"We would love to talk with you!"}
                    subtitleClassName={"pt-2 "}
                  />
                </div>
              </div>
            </div>
          </ContentContainer>
        </ContactSection>

        <div className={"flex flex-col items-center bg-white "}>
          <ContentContainer
            className={
              "flex flex-col justify-between  py-12 md:flex-row md:py-24"
            }
          >
            <div
              className={
                "flex w-full flex-col items-center justify-center " +
                getOpacityFromBottomTransition(
                  contactActionSectionInView.inView
                )
              }
            >
              <div
                className={
                  "flex w-full flex-col items-center text-xl font-bold md:block md:w-3/5 "
                }
              >
                <h2 className={"tracking-[0.2em] text-brand-text-turquoise"}>
                  Questions?
                </h2>
                <p
                  className={
                    "pt-4 text-center font-molle text-4xl  md:text-left md:text-5xl"
                  }
                >
                  Get in touch with us!
                </p>
              </div>
            </div>
            <div
              ref={contactInView.ref}
              className={
                "flex w-full flex-col justify-between" +
                getOpacityFromBottomTransition(
                  contactActionSectionInView.inView
                )
              }
            >
              <div
                ref={contactActionSectionInView.ref}
                className={"mt-4 text-center md:text-left"}
              >
                Part of our mission is to get to know like-minded people, so if
                you got any ideas, questions or simply want to join us on a
                hike, please email us at{" "}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={"mailto:hello@scrumify.at"}
                  className={" font-bold text-brand-pink underline "}
                >
                  hello@scrumify.at
                </a>
              </div>

              <div className={"mt-8  h-14 w-2/3 self-center md:self-auto"}>
                <PopInButton
                  href={"mailto:hello@scrumify.at"}
                  type={"submit"}
                  ariaLabel={"Write and email to us"}
                >
                  Get in touch
                </PopInButton>
              </div>
            </div>
          </ContentContainer>
        </div>
        <FooterBar />
      </div>
    </>
  );
};

export default Home;
