import React, {useState} from "react";
import {ChevronUp} from "react-feather";
import ContentContainer from "./ContentContainer";
import GradientHeader from "./GradientHeader";
import {useInView} from "react-intersection-observer";
import Link from "next/link";

const FooterBar = () => {
    const socialsInView = useInView({
        triggerOnce: true,
        rootMargin: "0px 0px",
    });
    const [isImprintOpen, setIsImprintOpen] = useState(false);
    return (
        <div
            ref={socialsInView.ref}
            className={"flex flex-col items-center bg-brand-black text-white"}
        >
            <ContentContainer>
                <div
                    className={
                        "flex h-full w-full  flex-col items-center text-center  justify-between py-8  pb-40 text-xs"
                    }
                >
                    <button
                        aria-label={"Show imprint"}
                        onClick={() => setIsImprintOpen((prevState) => !prevState)}
                        className={"flex  flex-row pt-8  pb-8"}
                    >
                        Imprint & Privacy Policy
                        <ChevronUp
                            className={
                                "-translate-y-1 transition-transform   duration-1000 " +
                                (!isImprintOpen && "rotate-180")
                            }
                        ></ChevronUp>
                    </button>

                    <div
                        className={
                            "flex flex-col items-center  overflow-hidden  transition-[height] transition-transform duration-1000 " +
                            (isImprintOpen ? "h-[1500px]  " : " h-[0px] ")
                        }
                    >
                        <GradientHeader>Company</GradientHeader>
                        Benjamin Thorstensen Bsc.
                            <br/>
                            Laaer-Berg-Straße 47/259 1100 Vienna, Austria
                        <GradientHeader>Contact</GradientHeader>
                        +43 660 38 411 40
                        <a
                            href={"mailto:benjamin.thorstensen@scrumify.at"}
                            className={"underline "}
                        >
                            benjamin.thorstensen@scrumify.at
                        </a>
                        <GradientHeader>UID-Number</GradientHeader>
                        ATU78136768
                            <br/>
                            Member of the Vienna Chamber of Commerce (Wirtschaftskammer Wien)
                        <GradientHeader>Professional Law</GradientHeader>
                            Commerce Order (Gewerbeordnung)
                            <br/>
                            www.ris.bka.gv.at
                            <br/>
                            Supervisory authority/commercial authority
                            <br/>
                            Authority according to ECG (E-Commerce Act) Municipal District
                            Office of the 10th District, MBA 10
                            <br/>
                            Consumers have the possibility to submit complaints to the EU
                            online dispute resolution platform: https://ec.europa.eu/odr
                            <br/>
                            You can also send any complaints to the above e-mail address.
                        <GradientHeader>
                            Liability for the content of this website
                        </GradientHeader>
                            I am constantly developing the content of this website and strive
                            to provide correct and up-to-date information. Unfortunately, I
                            cannot assume any liability for the correctness of all contents on
                            this website, especially for those provided by third parties.
                   <br/>
                            If you notice any problematic or illegal content, please contact
                            me immediately at the email mentioned above!
                        <GradientHeader>Copyright notice</GradientHeader>
                            All contents of this website (pictures, photos, texts, videos) are
                            subject to copyright. If necessary, I will legally pursue the
                            unauthorized use of parts of the content of my site.
                        <GradientHeader>Cookies</GradientHeader>
                     This website does not use any cookies.
                        <GradientHeader>Pictures</GradientHeader>

                            The pictures on this website were shot by

                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={"https://bulkin.photo/"}
                                className={"  underline "}
                            >
                                Yan Bululukov
                            </a>

                        <div className={"flex flex-col space-y-8 mb-11"}>
                            <div>
                                <p className="text-base font-semibold leading-7 text-primary mt-8 mb-1">Further Services and
                                    Websites by me and my dear colleague René:</p>
                                <p>
                                    My part time Business where help companies accelerate their success.
                                </p>

                                <Link className={'text-primary underline'} href={"https://www.kylo.at/"}
                                      passHref={true}>
                                    kylo.at
                                </Link>
                            </div>
                            <div>
                                <p>
                                Your look. Your style. Your AI photos – simple and professional in just minutes!
                                </p>
                                <Link className={'text-primary underline'} href={"https://bildki.com/"}
                                      passHref={true}>
                                   BILDKI.com
                                </Link>
                            </div>

                            <div>
                                <p>
                                    This Service built by my Partner René and me, connects the best Software Engineers
                                    in Vienna with hand picked Companies.
                                </p>
                                <Link className={'text-primary underline'} href={"https://juniornerd.at/"}
                                      passHref={true}>
                                    juniornerd.at
                                </Link>
                            </div>

                            <div>
                                <p>
                                    René is a passionate Software Engineer and website designer, check out his services.
                                </p>
                                <Link className={'text-primary underline'} href={"https://webgaudi.at/"}
                                      passHref={true}>
                                    webgaudi.at
                                </Link>
                            </div>

                            <div>
                                <p>
                                    This Band is fire, I built a website for them.
                                </p>
                                <Link className={'text-primary underline'} href={"https://ardenite.net/"}
                                      passHref={true}>
                                    ardenite.net
                                </Link>
                            </div>

                            <div>
                                <p>You dont need to spend a fortune to get an Amazing Music Video. </p>
                                <Link className={'text-primary underline'} href={"https://ai.kylo.at/"} passHref={true}>
                                    ai.kylo.at
                                </Link>
                            </div>
                        </div>



                    </div>
                        Copyright © {new Date().getFullYear()} Benjamin Thorstensen. All
                        rights reserved.
                </div>
            </ContentContainer>
        </div>
    );
};

export default FooterBar;
